const filteredCards = document.querySelectorAll('.projects .card');
const filterItems = (target) => {
  const filterKey = Object.keys(target.dataset)[0];
  const filterValue = target.dataset[filterKey];

  filteredCards.forEach(item => {
    const cardFilterKey = Object.keys(item.dataset);
    const cardFilterValue = Object.values(item.dataset);

    if (cardFilterKey.includes(filterKey) && cardFilterValue.includes(filterValue)) {
      item.classList.remove('hide');
      item.classList.add('show');
    } else {
      item.classList.remove('show');
      item.classList.add('hide');
    }
  });
};

document.addEventListener('click', (e)=> {
  const target = e.target;
  if(target.classList.contains('select-btn')) {
    target.classList.toggle('open')
  }
  if(target.classList.contains('select-item')) {
    [...target.closest('.select-list').children].forEach(item => item.classList.remove('selected'));
    target.classList.add('selected');
    filterItems(target);
    target.closest('.custom-select').querySelector('.select-btn').classList.remove('open');
  }

  if (!target.classList.contains('select-btn') && !target.classList.contains('select-item')) {
    const openButtons = document.querySelectorAll('.select-btn');
    openButtons.forEach(button => button.classList.remove('open'));
  }
})